import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
// import Sticky from 'react-sticky-el'
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import { BaseModalBackground } from "styled-react-modal"
import ProductList from "../components/ProductList"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Section,
  SectionHeading
} from "../styledComponents/section"
import { EventListSection } from "../styledComponents/eventList"

const Heading = ({ level = 1, location, children, offScreen }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} offScreen={offScreen}>
    {children}
  </SectionHeading>
)

const ShopIndex = ({
  data,
  location,
  pageContext: { nextPagePath, previousPagePath, currentPage, numPages },
}) => {
  let allproducts = data.allWpProducts.edges
  let allqtpendantproducts = data.allWpQtPendantProducts.edges
  let allaudiomeditations = data.allAudioMeditations.edges
  let allaudioprograms = data.allAudioPrograms.edges
  const [tabIndex, setTabIndex] = useState(0);

  const identity = useIdentityContext()
  const [processing, setProcessing] = useState(false)
  const updateRoles = ({ add, remove }) => {
    setProcessing(true)
    identity.authorizedFetch('/api/update-role', {
      method: 'POST',
      body: JSON.stringify({
        action: add ? 'add' : 'remove',
        role: add || remove
      })
    })
      .then(identity.refreshUser)
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const prod = urlParams.get('product')
    if(prod === 'quantum-touch' || prod === 'quantum-touch-pendants-malas'){
      setTabIndex(1)
    }
    if(prod === 'meditation' || prod === 'meditations'){
      setTabIndex(2)
    }
    if(prod === 'program' || prod === 'programs'){
      setTabIndex(3)
    }
    if (prod && identity.user) {
      updateRoles({ add: prod })
    }
  }, [])


  if (!allproducts.length) {
    return (
      <Layout location={location}>
        <SEO title="All products" />
        <p>
          No event posts found. Add events to your WordPress site and they'll
          appear here!
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} displayNavBorder>
      <SEO
        title="Mindfulness meditations and relaxation programs"
        description="Guided mindfulness audio meditations and programs to help boost your immunity and live a healthy, joyous and balanced life."
        url="/shop"
      />
      <Section>
        <Heading level={1} className="hidden" offScreen={true}> Audio Programs &amp; Meditations and </Heading>
        <EventListSection className="event-list">
          <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)} >
            {/* <Sticky boundaryElement=".tabslist" hideOnBoundaryHit={true}> */}
              <TabList className='react-tabs__tab-list product-list'>
                <Tab>All</Tab>
                <Tab>Quantum-Touch</Tab>
                <Tab>Audio Meditations</Tab>
                <Tab>Audio Programs</Tab>
              </TabList>
            {/* </Sticky> */}
            <TabPanel>
              <ProductList posts={allproducts} />
            </TabPanel>
            <TabPanel>
              <ProductList posts={allqtpendantproducts} />
            </TabPanel>
            <TabPanel>
              <ProductList posts={allaudiomeditations} />
            </TabPanel>
            <TabPanel>
              <ProductList posts={allaudioprograms} />
            </TabPanel>
          </Tabs>
        </EventListSection>
      </Section>
    </Layout>
  )
}

export default ShopIndex

export const pageQuery = graphql`
  query WordPressShopArchive {
    allWpProducts: allWpProduct(sort: {order: ASC, fields: menuOrder}, filter: {title: {ne: "Permission Bonus"}}) {
      edges {
        node {
          title
          uri
          id
          menuOrder
          excerpt
          productsTag {
            nodes {
              name
              slug
            }
          }
          productData {
            linkToAmazon
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            pricefullusd
            pricesaleusd
            stripefullpriceusd
            stripesalepriceusd
            hidesale
            hideonarchive 
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    },
    allWpQtPendantProducts:   allWpProduct(sort: {order: ASC, fields: menuOrder}, filter: {title: {ne: "Permission Bonus"}, productsTag: {nodes: {elemMatch: {slug: {eq: "qt-product"}}}}}) {
      edges {
        node {
          title
          uri
          id
          menuOrder
          excerpt
          productsTag {
            nodes {
              name
              slug
            }
          }
          productData {
            linkToAmazon
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            pricefullusd
            pricesaleusd
            stripefullpriceusd
            stripesalepriceusd
            hidesale
            hideonarchive 
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }, 
    allAudioMeditations:   allWpProduct(sort: {order: ASC, fields: menuOrder}, filter: {title: {ne: "Permission Bonus"}, productsTag: {nodes: {elemMatch: {slug: {eq: "audio-meditation"}}}}}) {
      edges {
        node {
          title
          uri
          id
          menuOrder
          excerpt
          productsTag {
            nodes {
              name
              slug
            }
          }
          productData {
            linkToAmazon
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            pricefullusd
            pricesaleusd
            stripefullpriceusd
            stripesalepriceusd
            hidesale
            hideonarchive 
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    },
    allAudioPrograms:   allWpProduct(sort: {order: ASC, fields: menuOrder}, filter: {title: {ne: "Permission Bonus"}, productsTag: {nodes: {elemMatch: {slug: {eq: "program"}}}}}) {
      edges {
        node {
          title
          uri
          id
          menuOrder
          excerpt
          productsTag {
            nodes {
              name
              slug
            }
          }
          productData {
            linkToAmazon
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            pricefullusd
            pricesaleusd
            stripefullpriceusd
            stripesalepriceusd
            hidesale
            hideonarchive 
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`
